import { AttachmentsListItemDropdownDownloadItem } from '../AttachmentsListItemDropdownDownloadButtons/AttachmentsListItemDropdownDownloadButtons.types';
import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';

import { DeleteFileAttachmentButton } from '../../../../../../../../fileAttachments/components/buttons/DeleteFileAttachmentButton';
import { DeleteSelectedMaterialButton } from '../../../../../../../../selectedMaterials/components/buttons/DeleteSelectedMaterialButton';
import { DeleteSelectedProductButton } from '../../../../../../../../selectedProducts/components/buttons/DeleteSelectedProductButton';
import { DeleteSelectedLifestyleButton } from '../../../../../../../../selectedLifestyles/components/buttons/DeleteSelectedLifestyleButton';
import { DeleteSourceFileButton } from '../../../../../../../../sourceFiles/components/DeleteSourceFileButton';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';

import { FileAttachmentTypesEnum } from '../../../../../../../../fileAttachments/fileAttachmentsConstants';
import { words } from '../../../../../../../../../locales/keys';
import { TasksPermissions } from '../../../../../../../../tasks/tasksConstants';
import { ProjectsPermissions } from '../../../../../../../../projects/projectsConstants';

interface AttachmentsListItemDropdownDeleteButtonsProps {
  item: AttachmentsListItemDropdownDownloadItem;
  cacheKeys: string[];
  taskNanoId: TaskNanoID;
}

function AttachmentsListItemDropdownDeleteButtons({
  item,
  cacheKeys,
  taskNanoId
}: AttachmentsListItemDropdownDeleteButtonsProps) {
  if (item.type === FileAttachmentTypesEnum.SOURCE_FILES) {
    return (
      <CheckPermissions
        action={
          TasksPermissions.READ_TASKS_ATTACHMENTS_DELETE_SOURCE_FILE_BUTTON
        }
      >
        <DeleteSourceFileButton
          i18nText={words.delete}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap text-red-500"
          sourceFileUuid={item.fileUuid}
          cacheKeys={cacheKeys}
        />
      </CheckPermissions>
    );
  }

  if (item.lifestyleUuid) {
    return (
      <CheckPermissions
        action={
          taskNanoId
            ? TasksPermissions.READ_TASKS_ATTACHMENTS_DELETE_SELECTED_LIFESTYLE_BUTTON
            : ProjectsPermissions.READ_PROJECTS_ATTACHMENTS_DELETE_SELECTED_LIFESTYLE_BUTTON
        }
      >
        <DeleteSelectedLifestyleButton
          i18nText={words.delete}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap text-red-500"
          selectedLifestyleUuid={item.attachmentUuid}
          cacheKey={cacheKeys}
        />
      </CheckPermissions>
    );
  }

  if (item.productUuid) {
    return (
      <CheckPermissions
        action={
          taskNanoId
            ? TasksPermissions.READ_TASKS_ATTACHMENTS_DELETE_SELECTED_PRODUCT_BUTTON
            : ProjectsPermissions.READ_PROJECTS_ATTACHMENTS_DELETE_SELECTED_PRODUCT_BUTTON
        }
      >
        <DeleteSelectedProductButton
          i18nText={words.delete}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap text-red-500"
          selectedProductUuid={item.attachmentUuid}
          cacheKey={cacheKeys}
        />
      </CheckPermissions>
    );
  }

  if (item.materialUuid) {
    return (
      <CheckPermissions
        action={
          taskNanoId
            ? TasksPermissions.READ_TASKS_ATTACHMENTS_DELETE_SELECTED_MATERIAL_BUTTON
            : ProjectsPermissions.READ_PROJECTS_ATTACHMENTS_DELETE_SELECTED_MATERIAL_BUTTON
        }
      >
        <DeleteSelectedMaterialButton
          i18nText={words.delete}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap text-red-500"
          selectedMaterialUuid={item.attachmentUuid}
          cacheKey={cacheKeys}
        />
      </CheckPermissions>
    );
  }

  if (item.type === FileAttachmentTypesEnum.IMAGES) {
    return (
      <CheckPermissions
        action={
          taskNanoId
            ? TasksPermissions.READ_TASKS_ATTACHMENTS_DELETE_IMAGE_BUTTON
            : ProjectsPermissions.READ_PROJECTS_ATTACHMENTS_DELETE_IMAGE_BUTTON
        }
      >
        <DeleteFileAttachmentButton
          fileAttachmentUuid={item.fileUuid}
          i18nText={words.delete}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap text-red-500"
          cacheKey={cacheKeys}
        />
      </CheckPermissions>
    );
  }

  return (
    <DeleteFileAttachmentButton
      fileAttachmentUuid={item.fileUuid}
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap text-red-500"
      i18nText={words.delete}
      cacheKey={cacheKeys}
    />
  );
}

export default AttachmentsListItemDropdownDeleteButtons;
