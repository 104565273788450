import { gql } from 'graphql-request';
import { UUID } from '../../../types';

export interface UpdateFileAttachmentQueryResponse {
  uuid: UUID;
  fileName: string;
  texture: boolean;
  threeD: boolean;
  drawing: boolean;
  reference: boolean;
}

export const UPDATE_FILE_ATTACHMENT_QUERY = gql`
  mutation UpdateFileAttachment(
    $uuid: ID!
    $name: String
    $threeD: Boolean
    $reference: Boolean
    $texture: Boolean
    $drawing: Boolean
  ) {
    updateFileAttachment(
      input: {
        uuid: $uuid
        name: $name
        threeD: $threeD
        reference: $reference
        texture: $texture
        drawing: $drawing
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
        name
        file
        size
        md5
        nanoId
        fileName
        ext
        threeD
        reference
        texture
        drawing
        createdAt
        updatedAt
        deletedAt
        user {
          id
          uuid
          name
          surname
          email
          surname
          createdAt
          updatedAt
        }
      }
      errors {
        name
        fullMessages
      }
    }
  }
`;
