import {
  DeleteFileAttachmentCacheKeys,
  DeleteFileAttachmentGqlQuery,
  DeleteFileAttachmentGqlStatus,
  FileAttachmentGqlError,
  FileAttachmentUUID
} from '../../fileAttachmentsTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

interface DeleteFileAttachmentInput {
  uuid: FileAttachmentUUID;
}

interface DeleteFileAttachmentResponse {
  deleteFileAttachment: {
    status: DeleteFileAttachmentGqlStatus;
    errors: DeleteFileAttachmentError;
  };
}

interface DeleteFileAttachmentError {
  fullMessages: FileAttachmentGqlError;
}

interface DeleteFileAttachmentOptions {
  cacheKeys: DeleteFileAttachmentCacheKeys;
  query: DeleteFileAttachmentGqlQuery;
}

const action = 'deleteFileAttachment';

function useDeleteFileAttachment({
  query,
  cacheKeys
}: DeleteFileAttachmentOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteFileAttachmentInput,
    DeleteFileAttachmentResponse,
    DeleteFileAttachmentError
  >({ action, cacheKeys, query });

  return {
    deleteFileAttachmentData: deleteQueryData,
    deleteFileAttachmentError: deleteQueryError,
    deleteFileAttachmentLoading: deleteQueryLoading,
    deleteFileAttachmentErrorMessage: deleteQueryErrorMessage,
    deleteFileAttachment: deleteQuery,
    deleteFileAttachmentReset: deleteQueryReset
  };
}

export default useDeleteFileAttachment;
