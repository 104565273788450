import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { FileAttachmentUUID } from '../../../fileAttachmentsTypes';
import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { DOWNLOAD_FILE_ATTACHMENT_QUERY } from '../../../queries/downloadFileAttachment.query';

import { useDownloadManagerModal } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/hooks/useDownloadManagerModal';
import { useDownloadFileAttachment } from '../../../hooks/useDownloadFileAttachment';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';

import { DownloadManagerModalItems } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/components/DownloadManagerModalItems';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { DownloadCache } from '../../../../downloads/DownloadCache';
import { downloadsKeys, words } from '../../../../../locales/keys';

interface DownloadFileAttachmentModalButtonProps {
  fileAttachmentUuid: FileAttachmentUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadFileAttachmentModalButton({
  fileAttachmentUuid,
  className,
  iconClassName,
  icon,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  i18nText
}: DownloadFileAttachmentModalButtonProps) {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    deleteDownloadsErrorMessage,
    deleteDownloadsLoading,
    handleClear,
    handleStopAll
  } = useDownloadManagerModal();

  const {
    downloadFileAttachmentLoading,
    downloadFileAttachmentErrorMessage,
    downloadFileAttachment
  } = useDownloadFileAttachment({
    query: DOWNLOAD_FILE_ATTACHMENT_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({
    error: downloadFileAttachmentErrorMessage || deleteDownloadsErrorMessage
  });

  const { downloadNanoId } = useDownloadNanoId();

  const handleDownload = useCallback(
    () =>
      downloadFileAttachment({
        uuid: fileAttachmentUuid,
        deviceNanoId: downloadNanoId
      }),
    [fileAttachmentUuid, downloadNanoId, downloadFileAttachment]
  );

  return (
    <SimpleModalButton
      className={className}
      i18nText={i18nText}
      iconClassName={iconClassName}
      icon={icon}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={downloadFileAttachmentLoading}
      tooltipSingleton={tooltipSingleton}
      onOpen={handleDownload}
      i18nTitle={downloadsKeys.plural}
      isModalOpen={downloadManagerBlockOpened}
      submitClass="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      submitButtonColor="gray"
      i18nSubmitText={words.stopAll}
      onSubmit={handleStopAll}
      onCancel={handleClear}
      i18nCancelText={words.clear}
      closeOnCancel={false}
      cancelButtonPosition="start"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      withoutButtons={isEmpty(downloads)}
      isLoading={deleteDownloadsLoading}
    >
      <DownloadManagerModalItems
        downloads={downloads}
        downloadsFetched={downloadsFetched}
        downloadsError={downloadsError}
      />
    </SimpleModalButton>
  );
}

export default DownloadFileAttachmentModalButton;
