import { TaskNanoID } from '../../../tasks/tasksTypes';

import {
  CreateMessageWithTaskNanoIdGqlQuery,
  CreateMessageWithTaskNanoIdGqlStatus,
  MessageGqlError,
  MessageUUID,
  MessageBody,
  MessageTaskNanoID,
  MessageVisibleForClient,
  MessageFileAttachmentIDs,
  MessageSelectedProductIDs,
  MessageSelectedLifestyleIDs,
  MessageColors,
  CreateMessageWithTaskNanoIdCacheKeys
} from '../../messagesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { TaskCache } from '../../../tasks/TaskCache';

interface CreateMessageWithTaskNanoIdInput {
  body: MessageBody;
  taskNanoId: MessageTaskNanoID;
  visibleForClient: MessageVisibleForClient;
  fileAttachmentIds?: MessageFileAttachmentIDs;
  selectedProductIds?: MessageSelectedProductIDs;
  selectedLifestyleIds?: MessageSelectedLifestyleIDs;
  colors?: MessageColors;
}

interface CreateMessageWithTaskNanoIdResponse<
  CreateMessageWithTaskNanoIdRecordType
> {
  createMessageWithTaskNanoId: {
    status: CreateMessageWithTaskNanoIdGqlStatus;
    recordUuid: MessageUUID;
    record: CreateMessageWithTaskNanoIdRecordType;
    errors: CreateMessageWithTaskNanoIdErrors;
  };
}

interface CreateMessageWithTaskNanoIdErrors {
  done: MessageGqlError;
  fullMessages: MessageGqlError;
  name: MessageGqlError;
  taskNanoId: MessageGqlError;
  visibleForClients: MessageGqlError;
}

interface CreateMessageWithTaskNanoIdOptions {
  cacheKeys?: CreateMessageWithTaskNanoIdCacheKeys;
  taskNanoId?: TaskNanoID;
  query: CreateMessageWithTaskNanoIdGqlQuery;
}

const action = 'createMessageWithTaskNanoId';

function useCreateMessageWithTaskNanoId<CreateMessageWithTaskNanoIdRecordType>({
  cacheKeys,
  taskNanoId,
  query
}: CreateMessageWithTaskNanoIdOptions) {
  const defaultCacheKeys = taskNanoId
    ? [
        TaskCache.messagesCacheKey(taskNanoId),
        TaskCache.sidebarMediaCacheKey(taskNanoId),
        TaskCache.imagesCacheKey(taskNanoId)
      ]
    : undefined;

  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateMessageWithTaskNanoIdInput,
    CreateMessageWithTaskNanoIdResponse<CreateMessageWithTaskNanoIdRecordType>,
    CreateMessageWithTaskNanoIdErrors,
    CreateMessageWithTaskNanoIdRecordType
  >({
    action,
    cacheKeys: cacheKeys || defaultCacheKeys,
    query
  });

  return {
    createMessageWithTaskNanoIdData: createQueryData,
    createMessageWithTaskNanoIdError: createQueryError,
    createMessageWithTaskNanoIdLoading: createQueryLoading,
    createMessageWithTaskNanoIdErrorMessage: createQueryErrorMessage,
    createMessageWithTaskNanoId: createQuery,
    createMessageWithTaskNanoIdReset: createQueryReset
  };
}

export default useCreateMessageWithTaskNanoId;
