import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  DeleteSelectedMaterialCacheKey,
  SelectedMaterialUUID
} from '../../../selectedMaterialsTypes';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDeleteSelectedMaterial } from '../../../hooks/useDeleteSelectedMaterial';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { MessageCache } from '../../../../messages/MessageCache';

import { DELETE_SELECTED_MATERIAL_QUERY } from '../../../queries/deleteSelectedMaterial.query';

interface DeleteSelectedMaterialButtonProps {
  cacheKey?: DeleteSelectedMaterialCacheKey;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  selectedMaterialUuid: SelectedMaterialUUID;
}

function DeleteSelectedMaterialButton({
  cacheKey,
  className,
  i18nText,
  icon,
  iconClassName,
  selectedMaterialUuid
}: DeleteSelectedMaterialButtonProps) {
  const {
    deleteSelectedMaterial,
    deleteSelectedMaterialLoading,
    deleteSelectedMaterialErrorMessage
  } = useDeleteSelectedMaterial({
    cacheKeys: [cacheKey || MessageCache.indexCacheKey()],
    query: DELETE_SELECTED_MATERIAL_QUERY
  });

  const handleDeleteSelectedMaterial = useCallback(
    () =>
      deleteSelectedMaterial({ uuid: selectedMaterialUuid }).catch((error) =>
        console.log(error)
      ),
    [deleteSelectedMaterial, selectedMaterialUuid]
  );

  useShowToastOnErrorChange({ error: deleteSelectedMaterialErrorMessage });

  return (
    <PureIconButtonHelper
      className={className}
      disabled={deleteSelectedMaterialLoading}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleDeleteSelectedMaterial}
    />
  );
}

export default DeleteSelectedMaterialButton;
