import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  DeleteFileAttachmentCacheKey,
  FileAttachmentUUID
} from '../../../fileAttachmentsTypes';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDeleteFileAttachment } from '../../../hooks/useDeleteFileAttachment';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { MessageCache } from '../../../../messages/MessageCache';

import { DELETE_FILE_ATTACHMENT_QUERY } from '../../../queries/deleteFileAttachment.query';

interface DeleteFileAttachmentButtonProps {
  cacheKey?: DeleteFileAttachmentCacheKey;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  fileAttachmentUuid: FileAttachmentUUID;
}

function DeleteFileAttachmentButton({
  cacheKey,
  className,
  i18nText,
  icon,
  iconClassName,
  fileAttachmentUuid
}: DeleteFileAttachmentButtonProps) {
  const {
    deleteFileAttachment,
    deleteFileAttachmentLoading,
    deleteFileAttachmentErrorMessage
  } = useDeleteFileAttachment({
    cacheKeys: [cacheKey || MessageCache.indexCacheKey()],
    query: DELETE_FILE_ATTACHMENT_QUERY
  });

  const handleDeleteFileAttachment = useCallback(
    () =>
      deleteFileAttachment({ uuid: fileAttachmentUuid }).catch((error) =>
        console.log(error)
      ),
    [deleteFileAttachment, fileAttachmentUuid]
  );

  useShowToastOnErrorChange({ error: deleteFileAttachmentErrorMessage });

  return (
    <PureIconButtonHelper
      className={className}
      disabled={deleteFileAttachmentLoading}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleDeleteFileAttachment}
    />
  );
}

export default DeleteFileAttachmentButton;
