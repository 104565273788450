import React, { memo } from 'react';
import cl from 'classnames';

import { TaskNanoID } from '../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { AttachmentsListAttachments } from './AttachmentsList.types';
import { UpdateFileAttachmentCacheQuery } from '../../../../fileAttachments/fileAttachmentsTypes';
import { UserNanoID } from '../../../../users/usersTypes';

import {
  Checkable,
  CheckedHashItem,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { AttachmentsListItem } from '../AttachmentsListItem';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';
import { FileAttachmentTypesEnum } from '../../../../fileAttachments/fileAttachmentsConstants';
import { FetchFileAttachmentsQueryResponse } from '../../../../fileAttachments/queries/fetchFileAttachments.query';

interface AttachmentsListProps {
  taskNanoId?: TaskNanoID;
  projectNanoId?: ProjectNanoID;
  userNanoId?: UserNanoID;
  items: AttachmentsListAttachments;
  onCheck?: OnSetCheckedIds;
  checkedHash?: CheckedHashItem;
  checkable: Checkable;
  modalMode?: boolean;
  isGridView?: boolean;
  onLightboxOpen?: OnLightboxOpenAction;
  folderType?: FileAttachmentTypesEnum;
  updateAttachmentCache?: UpdateFileAttachmentCacheQuery<FetchFileAttachmentsQueryResponse>;
}

function AttachmentsList({
  taskNanoId,
  projectNanoId,
  items,
  onCheck,
  checkable,
  checkedHash,
  modalMode,
  isGridView,
  onLightboxOpen,
  userNanoId,
  updateAttachmentCache
}: AttachmentsListProps) {
  return (
    <div
      className={cl({
        'grid grid-cols-auto-fill grid-cell-min-56 3xl:grid-cell-min-72 gap-2 pb-4':
          isGridView
      })}
    >
      {items.map((item) => (
        <AttachmentsListItem
          taskNanoId={taskNanoId}
          userNanoId={userNanoId}
          key={item.id}
          item={item}
          onCheck={onCheck}
          checked={checkedHash?.[item.id]}
          checkable={checkable}
          modalMode={modalMode}
          isGridView={isGridView}
          onLightboxOpen={onLightboxOpen}
          projectNanoId={projectNanoId}
          updateAttachmentCache={updateAttachmentCache}
        />
      ))}
    </div>
  );
}

export default memo<AttachmentsListProps>(AttachmentsList);
