import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { MaterialUUID } from '../../../materialsTypes';
import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useDownloadMaterial } from '../../../hooks/useDownloadMaterial';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadManagerModal } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/hooks/useDownloadManagerModal';

import { DownloadManagerModalItems } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/components/DownloadManagerModalItems';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';

import { downloadsKeys, words } from '../../../../../locales/keys';

interface DownloadMaterialModalButtonProps {
  materialUuid: MaterialUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadMaterialModalButton({
  materialUuid,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadMaterialModalButtonProps) {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    handleClear,
    handleStopAll,
    deleteDownloadsErrorMessage,
    deleteDownloadsLoading
  } = useDownloadManagerModal();

  const {
    downloadMaterialLoading,
    downloadMaterialErrorMessage,
    downloadMaterial
  } = useDownloadMaterial();

  useShowToastOnErrorChange({
    error: downloadMaterialErrorMessage || deleteDownloadsErrorMessage
  });

  const { downloadNanoId } = useDownloadNanoId();

  const handleDownload = useCallback(() => {
    downloadMaterial({
      uuid: materialUuid,
      deviceNanoId: downloadNanoId
    });
  }, [materialUuid, downloadNanoId, downloadMaterial]);

  return (
    <SimpleModalButton
      className={className}
      i18nText={i18nText}
      iconClassName={iconClassName}
      icon={icon}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={downloadMaterialLoading}
      tooltipSingleton={tooltipSingleton}
      onOpen={handleDownload}
      i18nTitle={downloadsKeys.plural}
      isModalOpen={downloadManagerBlockOpened}
      submitClass="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      submitButtonColor="gray"
      i18nSubmitText={words.stopAll}
      onSubmit={handleStopAll}
      onCancel={handleClear}
      i18nCancelText={words.clear}
      closeOnCancel={false}
      cancelButtonPosition="start"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      withoutButtons={isEmpty(downloads)}
      isLoading={deleteDownloadsLoading}
    >
      <DownloadManagerModalItems
        downloads={downloads}
        downloadsFetched={downloadsFetched}
        downloadsError={downloadsError}
      />
    </SimpleModalButton>
  );
}

export default DownloadMaterialModalButton;
