import React, { useCallback } from 'react';
import head from 'lodash/head';
import last from 'lodash/last';
import split from 'lodash/split';

import { AttachmentsListItemGridViewItem } from './AttachmentsListItemGridView.types';
import { UpdateFileAttachmentCacheQuery } from '../../../../../../fileAttachments/fileAttachmentsTypes';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';
import { UserNanoID } from '../../../../../../users/usersTypes';

import { FetchFileAttachmentsQueryResponse } from '../../../../../../fileAttachments/queries/fetchFileAttachments.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { AttachmentsListItemDropdownButtons } from '../AttachmentsListItemDropdownButtons';
import { UpdateFileAttachmentVisibleForClientButton } from '../../../../../../fileAttachments/components/buttons/UpdateFileAttachmentVisibleForClientButton';
import { FileAttachmentTypeBadge } from '../../../../../../fileAttachments/components/FileAttachmentTypeBadge';

import { Icon } from '../../../../../../../helpers/Icon';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { Checkbox } from '../../../../../../../helpers/Checkbox';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { getPreviewIcon } from './utils/getPreviewIcon';
import { Files } from '../../../../../../../utils/Files';

import { words } from '../../../../../../../locales/keys';

import { FileAttachmentTypesEnum } from '../../../../../../fileAttachments/fileAttachmentsConstants';

import { TasksPermissions } from '../../../../../../tasks/tasksConstants';
import { UsersPermissions } from '../../../../../../users/usersConstants';

type LinkHelperOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

interface AttachmentsListItemGridViewProps {
  item: AttachmentsListItemGridViewItem;
  handleCheck: () => void;
  checkboxChecked: boolean;
  checkable: boolean;
  onLightboxOpen?: () => void;
  cacheKeys?: string[];
  taskNanoId?: TaskNanoID;
  userNanoId?: UserNanoID;
  updateAttachmentCache?: UpdateFileAttachmentCacheQuery<FetchFileAttachmentsQueryResponse>;
}

function AttachmentsListItemGridView({
  item,
  handleCheck,
  checkboxChecked,
  checkable,
  onLightboxOpen,
  cacheKeys,
  taskNanoId,
  userNanoId,
  updateAttachmentCache
}: AttachmentsListItemGridViewProps) {
  const currentUser = useCurrentUser();

  const handleClick = useCallback<LinkHelperOnClick>(
    (e) => {
      if (onLightboxOpen) {
        e.preventDefault();
        onLightboxOpen();
        return;
      }
    },
    [onLightboxOpen]
  );

  const fileAttachmentAssignedTypeBadge = (
    <CheckPermissions
      action={
        userNanoId === currentUser.nanoId
          ? UsersPermissions.READ_SELF_PROFILE_FILE_TYPE_BADGE
          : UsersPermissions.READ_USER_PROFILE_FILE_TYPE_BADGE
      }
    >
      <FileAttachmentTypeBadge item={item} />
    </CheckPermissions>
  );

  const updateFileAttachmentVisibleForClientButton = (
    <>
      {item.type === FileAttachmentTypesEnum.FILES ||
      item.type === FileAttachmentTypesEnum.IMAGES ? (
        <CheckPermissions
          action={TasksPermissions.READ_FILE_ATTACHMENT_VISIBLE_FOR_CLIENTS}
        >
          <UpdateFileAttachmentVisibleForClientButton
            containerClassName="inline-flex"
            item={item}
            cacheKeys={cacheKeys}
            disabled={
              !currentUser.hasPermissions(
                TasksPermissions.CHANGE_FILE_ATTACHMENT_VISIBLE_FOR_CLIENTS
              )
            }
            updateAttachmentCache={updateAttachmentCache}
          />
        </CheckPermissions>
      ) : null}
    </>
  );

  return (
    <div className="flex flex-col relative rounded-md max-w-80 justify-self-center w-full border border-gray-200 dark:border-gray-700">
      {item.href && !Files.isImage(item.href) ? (
        <div className="flex flex-1 relative items-center justify-center text-gray-600 pt-6 pb-3">
          <Icon
            icon={getPreviewIcon(item.href)}
            className="h-12 w-12"
            strokeWidth="1"
          />
          <div className="absolute inset-0 flex items-end justify-start p-2 pointer-events-none space-x-1 z-5">
            {updateFileAttachmentVisibleForClientButton}
            {userNanoId && fileAttachmentAssignedTypeBadge}
          </div>
        </div>
      ) : null}
      {Files.isImage(item.href) ? (
        <div className="aspect-w-5 aspect-h-4 relative h-auto w-full">
          <ImageHelper
            className="absolute inset-0 object-cover bg-gray-100 dark:bg-gray-850 rounded-t-md"
            src={item.href}
            alt={item.name}
            version={FileAttachmentItemImageVersions.MiniThumb640x640}
          />
          <div className="absolute inset-0 flex items-end justify-start p-2 pointer-events-none space-x-1 z-5">
            {item.nda ? (
              <span className="px-1.5 py-0.5 rounded shadow text-xs bg-yellow-200 text-black">
                <Translate id={words.nda} />
              </span>
            ) : null}
            {item.i18nBadge}
            {updateFileAttachmentVisibleForClientButton}
            {userNanoId && fileAttachmentAssignedTypeBadge}
          </div>
        </div>
      ) : null}
      <div className="p-2 flex items-center space-x-2 2xl:space-x-4 truncate z-5 pointer-events-none">
        {checkable && (
          <div className="pointer-events-auto">
            <div className="flex items-center">
              <Checkbox checked={checkboxChecked} onChange={handleCheck} />
            </div>
          </div>
        )}
        <div className="flex-1 flex truncate leading-6">
          <span className="truncate">{head(split(item.name, '.')) + '.'}</span>
          <span>{last(split(item.name, '.'))}</span>
        </div>
        <AttachmentsListItemDropdownButtons
          item={item}
          isGridView
          cacheKeys={cacheKeys}
          taskNanoId={taskNanoId}
          userNanoId={userNanoId}
        />
      </div>
      <LinkHelper
        href={item.href}
        target={item.target}
        className="absolute inset-0 rounded-md focus:ring-base focus:ring-offset-0"
        data-id="attachment-link"
        onClick={handleClick}
      >
        <span className="sr-only">
          <span className="truncate">{item.name}</span>
        </span>
      </LinkHelper>
    </div>
  );
}

export default AttachmentsListItemGridView;
