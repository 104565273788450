import { AttachmentsListItemDropdownDownloadItem } from './AttachmentsListItemDropdownDownloadButtons.types';

import { DownloadLifestyleModalButton } from '../../../../../../../../lifestyles/components/buttons/DownloadLifestyleModalButton';
import { DownloadProductModalButton } from '../../../../../../../../products/components/buttons/DownloadProductModalButton';
import { DownloadMaterialModalButton } from '../../../../../../../../materials/components/buttons/DownloadMaterialModalButton';
import { DownloadSourceFileModalButton } from '../../../../../../../../sourceFiles/components/buttons/DownloadSourceFileModalButton';
import { DownloadFileAttachmentModalButton } from '../../../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentModalButton';

import { words } from '../../../../../../../../../locales/keys';
import { FileAttachmentTypesEnum } from '../../../../../../../../fileAttachments/fileAttachmentsConstants';

interface AttachmentsListItemDropdownDownloadButtonsProps {
  item: AttachmentsListItemDropdownDownloadItem;
}

function AttachmentsListItemDropdownDownloadButtons({
  item
}: AttachmentsListItemDropdownDownloadButtonsProps) {
  if (item.lifestyleUuid) {
    return (
      <DownloadLifestyleModalButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        lifestyleUuid={item.lifestyleUuid}
      />
    );
  }

  if (item.productUuid) {
    return (
      <DownloadProductModalButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        productUuid={item.productUuid}
      />
    );
  }

  if (item.materialUuid) {
    return (
      <DownloadMaterialModalButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        materialUuid={item.materialUuid}
      />
    );
  }

  if (item.type === FileAttachmentTypesEnum.SOURCE_FILES) {
    return (
      <DownloadSourceFileModalButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        sourceFileUuid={item.fileUuid}
      />
    );
  }

  return (
    <DownloadFileAttachmentModalButton
      i18nText={words.download}
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      fileAttachmentUuid={item.fileUuid}
    />
  );
}

export default AttachmentsListItemDropdownDownloadButtons;
