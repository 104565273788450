import { gql } from 'graphql-request';

import {
  MessageID,
  MessageBody,
  MessageUUID,
  MessageVisibleForClient,
  MessageRed,
  MessageFlagged,
  MessageColors,
  MessageFileAttachmentsUUID,
  MessageFileAttachmentsFile
} from '../messagesTypes';

export interface CreateMessageWithTaskNanoIdQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  visibleForClient: MessageVisibleForClient;
  red: MessageRed;
  flagged: MessageFlagged;
  colors: MessageColors;
  fileAttachments: {
    uuid: MessageFileAttachmentsUUID;
    file: MessageFileAttachmentsFile;
  };
}

export const CREATE_MESSAGE_WITH_TASK_NANO_ID = gql`
  mutation CreateMessageWithTaskNanoId(
    $taskNanoId: NanoId!
    $body: String!
    $visibleForClient: Boolean
    $red: Boolean
    $flagged: Boolean
    $propagate: Boolean
    $colors: [String!]
    $fileAttachmentIds: [ID!]
    $attachedFileAttachmentIds: [ID!]
    $selectedProductIds: [ID!]
    $selectedLifestyleIds: [ID!]
    $selectedMaterialIds: [ID!]
  ) {
    createMessageWithTaskNanoId(
      input: {
        taskNanoId: $taskNanoId
        body: $body
        visibleForClient: $visibleForClient
        red: $red
        flagged: $flagged
        propagate: $propagate
        colors: $colors
        fileAttachmentIds: $fileAttachmentIds
        attachedFileAttachmentIds: $attachedFileAttachmentIds
        selectedProductIds: $selectedProductIds
        selectedLifestyleIds: $selectedLifestyleIds
        selectedMaterialIds: $selectedMaterialIds
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        taskId
        body
        visibleForClient
        red
        flagged
        propagate
        colors
        fileAttachmentIds
        attachedFileAttachmentIds
        selectedProductIds
        selectedLifestyleIds
        selectedMaterialIds
        fullMessages
      }
    }
  }
`;
